<template>
  <div>
    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfix flex_i">
        <span class="title_cadr_block">{{ $t('message.blank_head') }}</span>
        <div class="d-flex"  style="float: right; padding: 3px 0">
          <el-button v-if="logo.length == 0" size="small" class="p-2" type="primary" @click="drawer.create.status = true" icon="el-icon-plus" circle></el-button>
          <el-button :disabled="logo.length == 0" size="small mr-2" class="p-2" type="success" @click="clickUpdate(logo)" icon="el-icon-edit" circle></el-button>
          <el-button :disabled="logo.length == 0" size="small" class="p-2" @click="destroy(logo)" type="danger" icon="el-icon-delete"  circle></el-button>
        </div>
      </div>
      <div class="my_letterhead text item">
        <img class="img_isolate" alt="" :src="logoimg ? (url + logoimg.path) : ''" />
        <ul>
            <li>{{logo.address}}</li>
            <li>{{logo.web}}</li>
            <li>{{logo.email}}</li>
            <li>
                <div>{{logo.number}}</div>
            </li>
        </ul>
      </div>
    </el-card>

       <el-drawer 
            size="60%" 
            :visible.sync="drawer.create.status"
            :ref="drawer.create.name"
            @opened="drawerOpened(drawer.create.component)"
            @closed="drawerClosed(drawer.create.component)">
                <crm-create :ref="drawer. create.component" :drawer-name="drawer.create.name"></crm-create>
        </el-drawer>

        <el-drawer 
            size="60%" 
            :visible.sync="drawer.update.status"
            :ref="drawer.update.name"
            @opened="drawerOpened(drawer.update.component)"
            @closed="drawerClosed(drawer.update.component)">
                <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"></crm-update>
        </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import {mapGetters,mapActions} from "vuex";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import printlogo from "@/utils/mixins/printlogo";
import drawer from "@/utils/mixins/drawer";
  import { i18n } from '@/utils/i18n';

export default {
  mixins: [list, printlogo, drawer],
  name: "letterhead",
  data() {
    return {
      url: process.env.VUE_APP_URL_DOCS,
      logo: [],
      logoimg: [],
      loading: false,
      selectedModel: '',
      drawer: {
        create: {
            name: "create",
            status: false,
            component: 'componentDrawerCreate'
        },
        update: {
            name: "update",
            status: false,
            component: 'componentDrawerUpdate'
        }
      }
    };
  },
  components: {CrmCreate,CrmUpdate },
  computed: {
      ...mapGetters({
          list: 'printlogos/list',
          columns: "printlogos/columns",
          pagination: "printlogos/pagination",
          filter: "printlogos/filter",
          sort: "printlogos/sort",
      }),
      actions: function () {
          return ['edit', 'delete'];
      }
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
        updateList: 'printlogos/index',
        updateSort: "printlogos/updateSort",
        updateColumn: "printlogos/updateColumn",
        updateFilter: "printlogos/updateFilter",
        updatePagination: "printlogos/updatePagination",
        empty: 'printlogos/empty',
        houseShow: 'printlogos/show',
        delete: 'printlogos/destroy',
        refreshData: 'printlogos/refreshData',
    }),
    clickUpdate(model) {
        this.fetchData();
        this.drawer.update.status = true;
        this.selectedModel = model
    },
    async fetchData() {
        this.drawerUpdate = false
        this.drawerCreate = false
        if (!this.loading) {
            this.loading = true;
            await this.updateList()
                .then(res => {
                    this.logo = res.data.printlogo;
                    this.logoimg = res.data.printlogo.images[0];
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        }
    },
    fetchDelete(model) {
        this.selectedModel = model;
    },
    destroy(obj) {
              this.$confirm(
          i18n.t('message.do_you_want'),
                     i18n.t('message.warning'), {
                        confirmButtonText: i18n.t('message.yes'),
                        cancelButtonText: i18n.t('message.cancel'),
                        type: "warning"
                    }
        ).then(() => {
          this.loading = true;
          this.delete(obj.id)
            .then(res => {
                this.$alert(res);
                this.loading = false;
                this.fetchData();
                this.logo = [];
                this.logoimg = [];
                this.fetchEmpty();
            })
            .catch(err => {
                this.$alert(err);
            });
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: i18n.t('message.cancel')
            });
          });
    },
    fetchEmpty() {
        this.empty();
    },
},
};
</script>
<style lang="scss" scoped>
    .my_letterhead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0px;

        img {
            width: 300px;
        }

        ul {
            text-align: right;
            list-style: none;
            line-height: 23px;

            li {
                font-size: 16px;
                font-weight: 700;
                line-height: 30px;
            }
        }
    }
    .title_cadr_block{
        padding-top: 10px;
        font-size: 16px;
        font-weight: 700;
    }
    .flex_i{
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .flex_i::after{
        content: none;
    }
    
</style>
